.navbar-navbar{
    display: flex;
    justify-content: space-around;
    background-color: white;
    padding: 0.5rem;
    
}

.nav-item{ color: black;}
/* .container-grid-admin {
    display: grid; 
    grid-template-columns: 1fr 1fr 1fr; 
    grid-template-rows: 1fr 1fr 1fr; 
    gap: 0px 0px; 
    grid-template-areas: 
      ". . ."
      ". . ."
      ". . ."; 
  } */

  .navbarSize {
color: #fff !important;
font-size: 17px;

  }